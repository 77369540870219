@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap');
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

*{
  font-family: 'Lato', sans-serif;
}
body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  background-color: #f6f6f6;
}

/* CHROME SCROLLBAR */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: rgb(200, 200, 200);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(170, 170, 170);
}

